import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

class Header extends Component {
  render() {
    return (
      <header>
        <a href="/">
          <span>Aero - Combat. Space</span>
        </a>

        <Navbar>
          <Container>
            <Nav className="me-auto">
                <Nav.Link href="/">Головна</Nav.Link>
                <Nav.Link href="/company" disabled>Компанії</Nav.Link>
                <Nav.Link href="/product" disabled>Продукція</Nav.Link>
                <Nav.Link href="/document">Законодовча база</Nav.Link>
                <Nav.Link href="/contact" >Контакти</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </header>
    )
  }
}

export default Header

//<Nav.Link href="/appeal">Звернення</Nav.Link>